import { CalendarEvent, CalendarEventAction } from 'angular-calendar';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, of, Subject } from 'rxjs';
import {
	concatMap,
	debounce,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	retry,
	timeout,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilService } from '../util/util.service';
import { FirebaseApiService } from 'mylib/services/firebase/firebase.api.service';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import QUESTIONS from 'src/assets/data/questions';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import {
	Client,
	ConnectionState,
	Conversation,
	Message,
	Participant,
} from '@twilio/conversations';
import { ConversationApiService } from 'mylib/services/conversations/conversations-api.service';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class MainService {
	paymentRequestedSubject$ = new Subject();
	job$ = new Subject();
	task$ = new Subject();
	_messagedAdded$ = new Subject();

	paymentRequestedObs$: Observable<any>;
	quest: any[] = [];
	currentStep = 0;
	previousStep = 0;
	steps: any[] = [];
	_showSpinner = false;
	_spinner = false;
	_orderSpinner = false;
	_done = false;
	_navFromCheckoutToBilling = false;
	derivedQuestIdx = 0;
	_savedQuests = false;
	_loading = false;
	_loadingAppoint = false;
	_loadingTasks = false;
	_loadingWR = false;
	currentScr = '';
	_isProfile = false;
	_myTeam: any[] = [];
	_loadChat = false;
	isLoginMode = true;

	newWr = {
		dueTodayPerc: 0.3, // Amount due today 30%
		requestType: '',
		optionalSce: [],
		globalTotal: 0,
		amountDueToday: 0, // total due today
		promoCode: '',
		orderId: '',
		receipt: '',
		created: '',
		currency: '',
		customer: '',
		paymentId: '',
		cc: '',
		status: '',
		clientFirstMsg: '',
		orderSummary: {
			selectedTax: {
				name: '',
			},
			co: [],
			taxShield: {},
			personal: {
				state: 0,
				fed: 0,
				taxType: '',
				states: '',
				totalStates: 0,
			},
		},
		payroll: {
			employees: 0,
			costPerEmployee: 8,
			feePerMonth: 15,
		},
		bookkeeping: {
			employees: '',
			cost: 0,
			costUpTo10: 99,
			costUpTo25: 199,
			costUpTo50: 349,
		},
		bizList: [],
		entity: {
			formation: 600,
			formationAndCompliance: 2000,
			state: '',
		},
		agentServices: {
			costPerState: 150,
			selectedStates: [],
			serviceAmount: 0,
		},
	};

	state: any = {
		userProvider: {},
		tasks: [],
		workRequests: [],
		appointments: { data: [] },
		selectedWR: {},
		selectedVendor: {},
		conversations: [],
		wRid: '',
	};

	tasksListener;
	workRequestsListener;
	appointmentsListener;
	convListner;
	_convLoader = false;
	getStarted = false; //for test
	_end = false;
	selectedTaxSteps = '';
	authEmail = { email: '', isLoginMode: false }; // check email if get started

	bizInstance: any = [];
	bizList: any = [];
	hiredVend: any = [];
	_viewPropDetails = '';
	_tab = 'Details';
	accordionOption = 'doc';
	pro = {
		_showPersonalInfo: false,
		_showProAuth: false,
		_login: false,
		_wrId: '',
		wrData: {},
		ppInfo: {
			firstName: '',
			middleName: '',
			lastName: '',
			nickName: '',
			phoneNumber: '',
			profilePhoto: '',
			haveBusiness: 'No',
			email: '',
			language: [
				{ l: 'English', sel: true },
				{ l: 'Spanish', sel: false },
				{ l: '', sel: false },
			],
			travel: [
				{ t: 'Pros travel to you', sel: false },
				{ t: 'I will travel with the pros', sel: false },
				{ t: 'I want to work remotely', sel: false },
			],
			completedStep: 0,
			address: { line1: '', line2: '', city: '', state: '', zipcode: '' },
			business: {
				name: '',
				phoneNumber: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipcode: '',
				},
			},
		},
	};

	conversationClient: Client;
	conversation: Conversation;
	chatList: Array<{
		chat: Conversation;
		unreadCount: number;
		lastMessage: string;
		sid?: string;
		time: string;
		wrId: string;
		requestType: string;

		vendorBusinessName: string;
		vendorFirstName: string;
		vendorLastName: string;
		vendorPhoto: string;

		userFirstName: string;
		userLastName: string;
		userPhoto: string;
		hide?: boolean;
		wrTitle?: string;
		createdAt?: any;
		_isCanceled?: boolean;
	}> = [];

	messages: Array<Message> = [];
	messageSending: boolean = false;
	message: string;
	error: string;
	currentConversation: Conversation;
	currentTime: Date;
	isTyping = false;
	_alreadyListening = false;
	_showChatRoom = false;
	_showChatList = false;
	_wrAlreadySaved = false;

	constructor(
		private util: UtilService,
		private storage: Storage,
		private http: HttpClient,
		private firebaseApi: FirebaseApiService,
		private store: AngularFirestore,
		private modalController: ModalController,
		private conversationsApi: ConversationApiService,
		private datePipe: DatePipe
	) {}

	/**------------------------------- fill out all quest
	 *
	 */
	// async saveQuestions(): Promise<any> {
	// 	console.log('creating Collection...');
	// 	const promisesList: Promise<any>[] = [];
	// 	console.log(' assets/questions', QUESTIONS);
	// 	QUESTIONS.forEach((el: any) => {
	// 		promisesList.push(this.firebaseApi.insertOne('questions', el));
	// 	});

	// 	Promise.all(promisesList)
	// 		.then(async (r) => console.log('create Collection res ->', r))
	// 		.catch((err) => console.log('create Collection err ->', err));
	// }

	/**--------------------------------------
	 *
	 */
	// async updateTargetLoc(): Promise<any> {
	// 	console.log('update licensed & target state, ...');

	// 	const promisesList: Promise<any>[] = [];
	// 	this._loading = true;

	// 	const questionnaires = await this.firebaseApi.findAll(
	// 		'questionnaire',
	// 		(ref: any) =>
	// 			ref.where('completed', '==', true).orderBy('createdAt', 'desc')
	// 	);

	// 	questionnaires.forEach((quests: any, index: number) => {
	// 		console.log(index, 'prev Quests', quests);
	// 		if (quests?.storedAnswersJson) {
	// 			console.log(
	// 				index,
	// 				'question..............',
	// 				quests.storedAnswersJson[1].question
	// 			);
	// 			quests.storedAnswersJson[1].answers.forEach((st: any) => {
	// 				st.taget = st.value;
	// 			});
	// 			const data = {
	// 				storedAnswersJson: quests?.storedAnswersJson,
	// 			};

	// 			console.log('new Quests', quests);

	// 			promisesList.push(
	// 				this.firebaseApi.updateOne('questionnaire', quests.id, data)
	// 			);
	// 		} else console.log('no questionnaires...');
	// 	});

	// 	Promise.all(promisesList)
	// 		.then(async (r) => console.log('batch res>>>>-> updated'))
	// 		.catch((err) => console.log('batch res>>>>->', err));
	// }

	/**-------------------------------------
	 *
	 * @returns
	 */
	async getFreshQuestions(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				this._loading = true;
				const res = await this.firebaseApi.findAll('questions', (ref) =>
					ref.orderBy('order', 'desc')
				);

				if (res.length > 0)
					this.quest = res.sort((a, b) =>
						a.order < b.order ? -1 : 1
					);

				this._loading = false;
				resolve(this.quest);
				//console.log('1 quest ->', this.quest);
			} catch (e) {
				this._loading = false;
				reject(e);
			}
		});
	}

	async showToast(message: string) {
		const toast = await this.util.createToast(message);
		await toast.present();
	}

	/**
	 * create customer
	 * @param data
	 * @param url
	 * @returns
	 */
	createCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams()
			.set('description', data.description)
			.set('email', data.email)
			.set('phone', data.phone)
			.set('name', data.name);
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 * check customer
	 * @param url
	 * @returns
	 */
	checkCustomer(url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/json',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};

		return this.http
			.post(url, {}, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	attachPmToCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams().set('customer', data.customer);
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	stripePay(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams()
			.set('amount', data.amount) //data.amount)
			.set('currency', data.currency)
			.set('customer', data.customer)
			.set('payment_method', data.payment_method)
			.set('confirm', data.confirm)
			.set('error_on_requires_action', data.error_on_requires_action);

		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 *
	 * @param action
	 * @param collection
	 * @param data
	 */
	async saveData(collection, id, data) {
		try {
			const res = await this.firebaseApi.insertOne(collection, data);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}

	/**
	 *
	 * @param action
	 * @param collection
	 * @param data
	 */
	async saveWrData(collection, id, data): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const res = await this.firebaseApi.insertOne(collection, data);
				resolve(res);
			} catch (error) {
				resolve(false);
				console.log(error);
			}
		});
	}

	sortAsc(data: any[], fieldName: string): any[] {
		// console.log('sort asc', data);
		const resultData = data
			.sort((a, b) => a[fieldName].com.localeCompare(b[fieldName]))
			.map((d) => d);
		return resultData;
	}

	/**
	 *
	 * @param data
	 */
	getData(url: string, data: any): Observable<any> {
		return this.http.post(url, data).pipe(timeout(5000), retry(1));
	}

	formatPhone(phone) {
		let len = phone.length;
		phone = len > 10 ? phone.substr(0, 10) : phone;
		phone =
			len > 3 ? phone.substr(0, 3) + '-' + phone.substr(3, len) : phone;
		phone =
			len > 6
				? phone.substr(0, 7) + '-' + phone.substr(7, len + 1)
				: phone;

		return phone;
	}

	getUnfinishedWork(workRequests) {
		return workRequests
			? workRequests.filter((w) => w.status === 'unfinished')
			: [];
	}

	getFinishedWork(workRequests) {
		return workRequests
			? workRequests.filter(
					(w) =>
						w.status === 'finished' &&
						w.progress !== 'complete' &&
						w.progress !== 'canceled'
			  )
			: [];
	}

	getClosedWork(workRequests) {
		return workRequests
			? workRequests.filter(
					(w) =>
						w.progress === 'complete' || w.progress === 'canceled'
			  )
			: [];
	}

	//------------ Lead watcher

	async getTasks(userId: string) {
		this._loadingTasks = true;

		const questionnaires = await this.firebaseApi.findAll('questionnaire');

		await this.firebaseApi
			.findAll('tasks', (ref) => ref.where('userId', '==', userId))
			.then(async (res) => {
				//add questionnaires
				res.forEach(
					(t) =>
						(t.vendorQuestionnaire = questionnaires.find(
							(q) => q.userId === t.vendorId
						))
				);

				// add vendor details
				let vend = {};
				res.forEach(async (el) => {
					if (el.vendorId) {
						vend = await this.firebaseApi.findOneById(
							'users',
							el.vendorId
						);
						el.vendor = vend;
					}
				});

				// filter hired vendors
				this.hiredVend = res.filter((v) => v.hired);

				this._myTeam = _.uniqBy(this.hiredVend, 'vendorId');
				console.log('_myTeam -> ', this._myTeam);

				// find reviews for hired vends
				this.hiredVend.forEach(async (el) => {
					const reviews = await this.firebaseApi.findAll(
						'reviews',
						(ref) => ref.where('vendorId', '==', el.vendorId)
					);
					if (reviews) {
						let initVal = 0;
						let sum = reviews.reduce(
							(prevVal, currentVal) =>
								prevVal + currentVal.rating,
							initVal
						);
						el.rating = Math.round(sum / reviews.length);
						el.reviews = reviews;
						el.totalReviews = reviews.length;
						//	console.log('hired vends ->', sum, this.hiredVend);
					}
				});

				// For New Task Notification
				if (this.state.tasks.length < res.length) {
					const newTasks: any[] = [];
					res.forEach((newTask) => {
						const findExistingTask = this.state.tasks.find(
							(t) => t.id === newTask.id
						);
						if (!findExistingTask) {
							newTasks.push(newTask);
						}
					});

					console.log('NEW TASKS -> ', newTasks);

					newTasks.forEach((nT) => {
						const foundWrIndex = this.state.workRequests.findIndex(
							(wR) => wR.id === nT.workRequestId
						);
						console.log('FOUND WR -> ', foundWrIndex);
						if (foundWrIndex >= 0) {
							console.log(
								'Setting New Task -> ',
								this.state.workRequests[foundWrIndex]
							);
							this.state.workRequests[foundWrIndex].newTask =
								true;

							if (
								this.state.selectedWR &&
								this.state.selectedWR.id &&
								this.state.workRequests[foundWrIndex].id ===
									this.state.selectedWR.id
							)
								this.state.selectedWR.newTask = true;
						}
					});
				}
				this.state.tasks = res;
				this._loadingTasks = false;

				// notify module related to task update (document.component)
				this.task$.next('');
			})
			.catch((e) => {
				console.log('err loading tasks', e);
				this._loadingTasks = false;
			});
	}

	/**----------------------------------------------------------------------------
	 *
	 * @param userId
	 * @returns
	 */
	async getWorkRequests(userId) {
		return new Promise(async (resolve, reject) => {
			try {
				this._loadingWR = true;
				const res = await this.firebaseApi.findAll(
					'workRequests',
					(ref) =>
						ref
							.where('userId', '==', userId)
							.orderBy('createdAt', 'desc')
				);

				this._loadingWR = false;
				if (res && res.length > 0) {
					this.state.workRequests = res;
					console.log('2 STATE ->', this.state);

					resolve(res);
				} else {
					resolve([]);
				}
			} catch (e) {
				console.log('err loading workRequests', e);
				this._loadingWR = false;
				reject(e);
			}
		});
	}

	/**-------------------------------------------------
	 *
	 * @param userId
	 */
	async listenAppointmentsChange(userId) {
		try {
			const apptCollection = this.store.collection<any>('appointments');
			this.appointmentsListener = apptCollection
				.snapshotChanges()
				.pipe(distinctUntilChanged())
				.subscribe((c: any) => {
					console.log('Listen Appointment', new Date(), c);
					this.getAppointments(userId);
				});
		} catch (e) {
			console.log('Abort Listener', e);
		}
	}

	async getAppointments(userId) {
		this._loadingAppoint = true;

		await this.firebaseApi
			.findAll('appointments', (ref) => ref.where('userId', '==', userId))
			.then(async (appointments) => {
				console.log('loaded appointments', appointments);
				if (appointments.length > 0) {
					await new Promise((resolve, reject) => {
						appointments.forEach(async (wR, index) => {
							let arr = wR.appointmentDate.split(/[- :]/);
							wR.appointmentDate = new Date(
								arr[0],
								arr[1] - 1,
								arr[2],
								arr[3],
								arr[4],
								arr[5]
							);

							let arr2 = wR.appointmentDateEnd.split(/[- :]/);
							wR.appointmentDateEnd = new Date(
								arr2[0],
								arr2[1] - 1,
								arr2[2],
								arr2[3],
								arr2[4],
								arr2[5]
							);
							console.log('APPT SINGLE -> ', wR);
							try {
								wR.userInfo =
									await this.firebaseApi.findOneById(
										'users',
										wR.userId
									);
								wR.vendorInfo =
									await this.firebaseApi.findOneById(
										'users',
										wR.vendorId
									);
								wR.workRequest =
									await this.firebaseApi.findOneById(
										'workRequests',
										wR.workRequestId
									);
								console.log(index + 1, appointments.length);
								if (index + 1 === appointments.length)
									resolve(true);
							} catch (e) {
								reject(e);
							}
						});
					});
					const actions: CalendarEventAction[] = [
						{
							label: '<span style="color:red;">Delete</span>',
							a11yLabel: 'Delete',
							onClick: async ({
								event,
							}: {
								event: CalendarEvent;
							}) => {
								console.log('EVENT', event);
								await this.firebaseApi.delete(
									'appointments',
									event.meta.id
								);
								const findIndex =
									this.state.appointments.data.findIndex(
										(a) => a.meta.id === event.meta.id
									);

								if (findIndex >= 0) {
									console.log('Found Index -> ', findIndex);
									this.state.appointments.data.splice(
										findIndex,
										1
									);
									console.log(
										'Appts -> ',
										this.state.appointments.data
									);
								}
							},
						},
					];
					this.state.appointments.data = appointments.map((appt) => {
						const calendarEventToAdd: CalendarEvent = {
							start: new Date(appt.appointmentDate),
							end: new Date(appt.appointmentDateEnd),
							title: `A meeting between tax preparer ${appt.vendorInfo?.firstName} ${appt.vendorInfo?.lastName} and tax filer ${appt.userInfo?.firstName} ${appt.userInfo?.lastName}`,
							color: {
								primary: '#e3bc08',
								secondary: '#FDF1BA',
							},
							meta: appt,
							actions,
						};
						return calendarEventToAdd;
					});

					console.log('3- Appts STATE -> ', this.state);
				} else this.state.appointments.data = [];
				this._loadingAppoint = false;
			})
			.catch((e) => {
				console.log('err loading appointments', e);
				this._loadingAppoint = false;
			});
	}

	async listenTaskChanges(userId) {
		try {
			const tasksCollection = this.store.collection<any>('tasks', (ref) =>
				ref.where('userId', '==', userId)
			);

			this.tasksListener = tasksCollection
				.valueChanges(['added', 'modified'])
				.pipe(debounceTime(3000))
				.subscribe((action) => {
					console.log('Listen tasks', new Date(), action);
					this.getTasks(userId);
				});
		} catch (e) {
			console.log('Abort Listener', e);
		}
	}

	async listenWorkRequestChanges(userId) {
		try {
			const workCollection = this.store.collection<any>(
				'workRequests',
				(ref) => ref.where('userId', '==', userId)
			);

			this.workRequestsListener = workCollection
				.snapshotChanges(['added', 'modified'])
				.pipe(distinctUntilChanged())
				.pipe(
					map((actions) => {
						return actions.map((a: any) => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							const alreadyExists =
								this.state.workRequests.findIndex(
									(wR) => wR.id === id
								);

							if (alreadyExists >= 0) {
								return {
									type: 'modified',
									data: { id, ...data },
								};
							} else {
								return {
									type: 'added',
									data: { id, ...data },
								};
							}
						});
					})
				)
				.subscribe(async (snaps: any) => {
					console.log('Listen Workrequests', new Date(), snaps);
					if (snaps.length > 0) {
						snaps.forEach((snap) => {
							switch (snap.type) {
								case 'added': {
									const findIndex =
										this.state.workRequests.findIndex(
											(wR) => wR.id === snap.data.id
										);
									if (findIndex === 0) {
									} else {
										this.state.workRequests.unshift(
											snap.data
										);
									}
									break;
								}
								case 'modified': {
									const foundIndex =
										this.state.workRequests.findIndex(
											(t) => t.id === snap.data.id
										);

									if (foundIndex >= 0) {
										if (
											this.state.workRequests[foundIndex]
												.cpaDocs.length <
											snap.data.cpaDocs.length
										) {
											snap.data.newDocument = true;
										}
										this.state.workRequests[foundIndex] =
											snap.data;
									}

									if (snap.data.id === this.state.wRid) {
										if (
											snap.data.progress !== 'complete' &&
											snap.data.cpaDocs.find(
												(doc) =>
													doc.documentSigned === true
											)
										) {
											console.log('TIME TO PAY');
											this.paymentRequestedSubject$.next(
												true
											);
										}
									}

									// console.log(
									// 	'Assigned Selected WR  -> ',
									// 	snap?.data?.id,
									// 	this.state.selectedWR?.id,
									// 	snap?.data,
									// 	this.state.selectedWR
									// );
									if (
										this.state.selectedWR &&
										this.state.selectedWR.id &&
										snap?.data?.id ===
											this.state.selectedWR?.id
									) {
										this.state.selectedWR = snap.data;
									}
									this.task$.next('');
									break;
								}
							}
						});
						console.log('4 STATE ->', this.state);
					}
				});
		} catch (e) {
			console.log('Abort Listener', e);
		}
	}

	get myProposals() {
		let prop = [];
		if (this.state.tasks.length > 0)
			prop = this.state.tasks.filter(
				(t) => t.workRequestId === this.state.wRid
			);
		return prop;
	}

	async initOrderSummary() {
		this._end = false;
		this.bizInstance = [];
		this.bizList = [];
		await this.storage.remove('nexgenUserQuest');
		this.newWr = {
			dueTodayPerc: 0.3, // Amount due today 30%
			requestType: '',
			optionalSce: [],
			globalTotal: 0,
			amountDueToday: 0, // total due today
			promoCode: '',
			orderId: '',
			receipt: '',
			created: '',
			currency: '',
			customer: '',
			paymentId: '',
			cc: '',
			status: '',
			clientFirstMsg: '',
			orderSummary: {
				selectedTax: {
					name: '',
				},
				co: [],
				taxShield: {},
				personal: {
					state: 0,
					fed: 0,
					taxType: '',
					states: '',
					totalStates: 0,
				},
			},
			payroll: {
				employees: 0,
				costPerEmployee: 8,
				feePerMonth: 15,
			},
			bookkeeping: {
				employees: '',
				cost: 0,
				costUpTo10: 99,
				costUpTo25: 199,
				costUpTo50: 349,
			},
			bizList: [],
			entity: {
				formation: 600,
				formationAndCompliance: 2000,
				state: '',
			},
			agentServices: {
				costPerState: 150,
				selectedStates: [],
				serviceAmount: 0,
			},
		};

		//	this.getFreshQuestions()
	}

	loadPricing(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const pricing = await this.firebaseApi.findAll('pricing');
				resolve(pricing);
			} catch (e) {
				reject(e);
			}
		});
	}
	quests = [];

	dismiss() {
		console.log('dismissed');
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	get totalUnreadMsg() {
		let totalUnreadMsg = 0;
		this.chatList.forEach((el) => {
			totalUnreadMsg += el.unreadCount;
		});
		return totalUnreadMsg;
	}

	resetChat() {
		this.currentConversation = null;
		this._showChatRoom = false;
		this._showChatList = false;
	}

	thisWr(wrId: string) {
		return this.state.workRequests.find((wR: any) => wR.id === wrId);
	}

	/**--------------------
	 *
	 * @param i
	 */
	checkMsgDate(i: number): string {
		let chatDate = '';
		let prevDate = '';
		const curDate = this.datePipe.transform(
			this.messages[i].dateUpdated,
			'MM.dd.yy'
		);

		if (i > 0)
			prevDate = this.datePipe.transform(
				this.messages[i - 1].dateUpdated,
				'MM.dd.yy'
			);

		chatDate = prevDate === curDate ? '' : curDate;
		const today = this.datePipe.transform(new Date(), 'MM.dd.yy');
		if (chatDate && chatDate === today) chatDate = 'Today';
		return chatDate;
	}

	getPackageType(request: any) {
		const packageTypeString: string[] = [];
		if (
			request?.requestType.indexOf('Tax Return') >= 0 &&
			request.packageType &&
			request.packageType.length > 0
		) {
			if (
				request.packageType.includes('basic') ||
				request.packageType.includes('standard') ||
				request.packageType.includes('premium') ||
				request.packageType.includes('self-employed')
			) {
				packageTypeString.push('1040 | ');
				request.packageType.forEach((pT) => {
					if (pT !== 'business') {
						packageTypeString.push(
							pT.charAt(0).toUpperCase() + pT.slice(1)
						);
						if (request.packageType.includes('business')) {
							packageTypeString.push(' + ');
						}
					}
				});
			} else if (request?.requestType.indexOf('Non-Profit') >= 0)
				packageTypeString.push(request.requestType);
			else if (request?.requestType.indexOf('Business') >= 0)
				packageTypeString.push('Business');

			if (request.packageType.includes('business')) {
				if (request?.bizList.length > 0) {
					request.bizList.forEach((b, index) => {
						const businessType = b.data
							?.find((q) => q.key === 'q13')
							?.answers?.find((a) => a.value === true)?.name;
						if (businessType) {
							packageTypeString.push(businessType.toUpperCase());
							if (index + 1 < request.bizList.length)
								packageTypeString.push(', ');
						}
					});
				}
			}
		} else if (request?.requestType) {
			packageTypeString.push(request.requestType);
		}
		const p = packageTypeString.join('');
		return p;
	}

	getRequestYear(request: any) {
		let requestYear = '';
		const y = request?.requestType;
		const quest =
			y === 'Non-Profit Tax Return'
				? 'q25.2'
				: y === 'Business Tax Return'
				? 'q23.2'
				: y === 'Tax Audit / Lien / Levy'
				? 'q50'
				: y === 'I Received a Notice'
				? 'q60'
				: y === 'Tax Consultation/Other Issues'
				? 'q70'
				: y === 'Company Formation' ||
				  y === 'Accounting or Bookkeeping' ||
				  y === 'Payroll'
				? ''
				: 'q2';

		if (quest) {
			const n =
				request && request.questionnaire
					? request.questionnaire
							.find((q) => q.key === quest)
							.answers?.find((a) => a.value)
					: '';
			if (n && n.value && ['q50', 'q60', 'q70'].includes(quest))
				requestYear = n.value;
			else if (n && n.name) requestYear = n.name;
		}
		return requestYear || '';
	}

	getPackageNames(request: any) {
		const string: any[] = [];
		let bizType = 0;
		if (request?.bizList) {
			request?.bizList.forEach((biz: any) => {
				biz.data.forEach((q: any) => {
					if (q.question === 'What type of business do you have?') {
						q.answers.forEach((a: any, idx: number) => {
							if (a.value) {
								string.push(
									bizType > 0 ? ', ' + a.name : a.name
								);
								++bizType;
							}
						});
					}
				});
			});
		}

		return string.length > 0 ? string.join('') : '';
	}

	getFullPackage(wr: any) {
		let wrTitle = '';
		const wrYear = wr && wr.id ? this.getRequestYear(wr) : '';
		const wrPack = wr && wr.id ? this.getPackageType(wr) : '';
		const pn = this.getPackageNames(wr);
		wrTitle = wrYear ? wrYear + ' - ' + wrPack : wrPack;
		wrTitle += pn ? ' | ' + pn : '';
		return wrTitle;
	}

	/**------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	/**------------------------------------------------------------------
	 *
	 * @param userId
	 */
	async listenConversations(userId) {
		try {
			const tasksCollection = this.store.collection<any>('tasks', (ref) =>
				ref.where('userId', '==', userId)
			);

			this.tasksListener = tasksCollection
				.valueChanges(['added'])
				.pipe(debounceTime(3000))
				.subscribe((action) => {
					console.log('Listen conversation', new Date(), action);
					if (action && action.length > 0) {
						this.checkConversations(userId, action);
					}
				});
		} catch (e) {
			console.log('Abort Listener', e);
		}
	}

	/**--------------------------------------------------------------------
	 *
	 * @param userId
	 * @param action
	 * @returns
	 */
	private checkConversations(userId: string, action: any) {
		const processedTasks: any[] = [];
		return new Promise<any>(async (resolve, reject) => {
			try {
				//check conversation
				const convList =
					await this.firebaseApi.findConversationsByParticipant(
						this.state.userProvider.id
					);

				if (action && action.length > 0) {
					let index = 0;
					for (const task of action) {
						const idx = convList.findIndex(
							(conv: any) =>
								conv.participants.includes(userId) &&
								conv.participants.includes(task.vendorId) &&
								conv.workRequestId === task.workRequestId
						);

						if (idx === -1) {
							// check if task is already processed
							const taskExist = processedTasks.filter(
								(t: any) =>
									t.userId === userId &&
									t.vendorId === task.vendor &&
									t.workRequestId === task.workRequestId
							);

							if (taskExist && taskExist.length === 0) {
								processedTasks.push({
									userId: userId,
									vendorId: task.vendorId,
									workRequestId: task.workRequestId,
								});
								await this.createConversation(task);
							}
						}
						++index;
						// console.log(index, '### -------');
					}

					//console.log('#### -  new tasks, create conv, update chat');
					this.connectTwilio(userId);
					//console.log('#### - new tasks  end. ');
				}

				resolve(true);
			} catch (e) {
				resolve(false);
				console.log('check conversation err -=>', e);
			}
		});
	}

	/**---------------------------------------------------------------------------
	 *
	 * @param task
	 * @returns
	 */
	async createConversation(task: any) {
		return new Promise<any>(async (resolve, reject) => {
			try {
				console.log('conv >>>>>> create');
				const participants: string[] = [task.vendorId, task.userId];

				// check if conv already exist
				if (participants && participants.length === 2) {
					const convExist = await this.firebaseApi.findAll(
						'conversations',
						(ref) =>
							ref
								.where('userId', 'in', participants)
								.where('vendorId', 'in', participants)
								.where(
									'workRequestId',
									'==',
									task.workRequestId
								)
					);

					if (convExist && convExist.length === 0) {
						const conversation =
							await this.conversationsApi.createConversation({
								participants,
							});

						// find the related WR
						const wr = await this.firebaseApi.findOneById(
							'workRequests',
							task.workRequestId
						);

						const wrTitle = this.getFullPackage(wr);
						const conversationData = {
							conversationSid: conversation.sid,
							workRequestId: task.workRequestId,
							participants,
							vendorName: task.vendorName,

							requestType: task.requestType,
							wrTitle: wrTitle,
							vendorBusinessName: task.vendorBusinessName,
							vendorFirstName: task.vendorFirstName,
							vendorLastName: task.vendorLastName,

							vendorPhoto: task.vendorPhoto,
							vendorId: task?.vendorId ? task.vendorId : '',
							clientFirstMsg: task.clientFirstMsg,

							userId: task.userId,
							userFirstName: task.userFirstName,
							userLastName: task.userLastName,
							userPhoto: task.userPhoto,
						};

						//insert conv
						await this.firebaseApi.insertOne(
							'conversations',
							conversationData
						);

						console.log('conv >>>>>> created');
					}
				}
				resolve(true);
			} catch (e) {
				reject({ msg: e });
				console.log('e: ', e);
				throw new Error('Could not create conversation. Error: ' + e);
			}
		});
	}

	/**----------------------------------------------connect Twilio
	 *
	 */
	async connectTwilio(userId: string) {
		if (!this.conversationClient && !this._alreadyListening) {
			this._convLoader = true;
			try {
				const token = await this.getToken(
					this.state.userProvider.id,
					this.state.userProvider.email
				);
				this.conversationClient = new Client(token);
				this._convLoader = false;
				this._alreadyListening = true;
				this.listenToEvents(userId);
			} catch (e) {
				this._convLoader = false;
				console.log(' get token error', e);
			}
		} else {
			console.log('### conv client already connected--------------->>');
			await this.fetchUserChats(userId);
		}
	}

	/**--------------------------------------------
	 *
	 */
	async listenToEvents(userId) {
		console.log('list conv events -==----------- ');
		this.conversationClient.on('initialized', async () => {
			console.log('Client initialized--------------->> listening');
			await this.fetchUserChats(userId);
		});

		this.conversationClient.on('initFailed', (error: any) => {
			console.log('Client initialization failed: ', error);
		});

		this.conversationClient.on(
			'connectionStateChanged',
			(state: ConnectionState) => {
				console.log('Connection state change: ', state);
			}
		);

		this.conversationClient.on('connectionError', (error: any) => {
			console.log('Connection error: ', error);
		});

		this.conversationClient.on('tokenAboutToExpire', async () => {
			console.log('About to expire');
			const token = await this.getToken(
				this.state.userProvider.id,
				this.state.userProvider.email
			);

			this.conversationClient = await this.conversationClient.updateToken(
				token
			);
		});

		this.conversationClient.on('tokenExpired', () => {
			console.log('Token expired');
			this.conversationClient.removeAllListeners();
		});

		this.conversationClient.on(
			'conversationAdded',
			(conv: Conversation) => {
				this.currentTime = new Date();
				setTimeout(async () => {
					if (
						conv.dateCreated &&
						conv.dateCreated > this.currentTime
					) {
						console.log('Conversation added', conv);
						await conv.setAllMessagesUnread();
						//addMessageToChatList(conv)
					}
				}, 500);
			}
		);

		this.conversationClient.on('messageAdded', async (msg: Message) => {
			console.log('Message added', msg);
			if (
				this.currentConversation &&
				this.currentConversation?.sid === msg.conversation?.sid
			) {
				this.messages.push(msg);
				await this.currentConversation.updateLastReadMessageIndex(
					msg.index
				);
				this.chatList = this.chatList.map((el: any) => {
					if (el.chat.sid === this.currentConversation?.sid) {
						el.lastMessage = msg.body;

						let time = '...';
						const dt = el.chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;

							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}
						el.time = time;
					}
					return el;
				});

				this.sortChatList();

				this._messagedAdded$.next('');
			} else {
				this.chatList = this.chatList.map((el: any) => {
					if (el.chat.sid === msg.conversation.sid) {
						el.lastMessage = msg.body;
						el.unreadCount++;
						let time = '...';
						const dt = el.chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;
							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}
						el.time = time;
					}
					return el;
				});

				this.sortChatList();
			}
			// this.scrollToBottom();
		});

		this.conversationClient.on('typingStarted', (user: Participant) => {
			// console.log('typing..', user);
			if (user?.conversation?.sid === this.currentConversation?.sid)
				this.isTyping = true;
		});

		this.conversationClient.on('typingEnded', (user: Participant) => {
			console.log('typing end..', user);
			if (user?.conversation?.sid === this.currentConversation?.sid)
				this.isTyping = false;
		});
	}

	/**-------------------------------------------getToken
	 *
	 * @param userId
	 * @param userEmail
	 * @returns
	 */
	async getToken(userId: string, userEmail: string) {
		const url = environment.conversationApi + 'api/chat/token';

		const { token } = await this.http
			.post<any>(url, {
				userId,
				userEmail,
			})
			.toPromise();
		return token;
	}

	/**-------------------------------------------------
	 *
	 * @param userId
	 */
	async fetchUserChats(userId: string) {
		return new Promise<any>(async (resolve, reject) => {
			if (this.conversationClient) {
				console.log('#### 1- chat fetch started');
				const f: any[] = await this.fetchUserChatsTemp(userId);
				if (f && f.length > 0) {
					this.chatList = f;
					this.sortChatList();
				}
				console.log('#### 4- chat fetch done');
				resolve(true);
			} else {
				console.log('#### chat fetch skipped');
				resolve(true);
			}
		});
	}

	/**-------------------------------------------------------
	 *
	 */

	sortChatList() {
		if (this.chatList && this.chatList.length > 0) {
			// Sort by `name` property in ascending order
			this.chatList.sort((a, b) =>
				(a.vendorFirstName + ' ' + a.vendorLastName).localeCompare(
					b.vendorFirstName + ' ' + b.vendorLastName
				)
			);
			let user = '';
			this.chatList.forEach((el) => {
				if (user !== el.vendorFirstName) {
					user = el.vendorFirstName;
				} else {
					el.hide = true;
				}
			});
			console.log('Chat list >>>>>>>>>>>++++++++>', this.chatList);
		}
	}

	/**-------------------------------------------------------
	 *
	 * @param userId
	 * @returns
	 */
	async fetchUserChatsTemp(userId: string) {
		return new Promise<any>(async (resolve, reject) => {
			try {
				let chatListTemp = [];
				this._loadChat = true;
				console.log('#### 2- start temp fetch');
				const c = await this.firebaseApi.findConversationsByParticipant(
					userId
				);
				// filter conversations
				const conversations = c.filter((el: any) => !el?.ticketId);

				if (conversations && conversations.length > 0) {
					let index = 0;
					for (let myConv of conversations) {
						const chat =
							await this.conversationClient.getConversationBySid(
								myConv.conversationSid
							);

						const unreadCount = await chat.getUnreadMessagesCount();
						const lastMessage: any = await chat.getMessages();
						let body: any;

						if (lastMessage && lastMessage.items.length > 0) {
							const idx = chat.lastReadMessageIndex || 0;

							body =
								lastMessage.items[idx] &&
								lastMessage.items[idx].body
									? lastMessage.items[idx].body
									: 'Hi! How may I help you?';
						}

						let time = '';
						const dt = chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;

							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}

						// find the the work request status & if already hired
						let _isCanceled = false;

						const _wrId = myConv.workRequestId;
						if (_wrId) {
							const c = await this.firebaseApi.findOneById(
								'workRequests',
								myConv.workRequestId
							);

							if (c.progress === 'canceled') _isCanceled = true;
						}

						let obj: any = {
							chat,
							unreadCount: unreadCount ? unreadCount : 0,
							sid: chat.sid,
							lastMessage: body ? body : '',
							time,

							requestType: myConv.requestType,
							wrId: myConv.workRequestId,
							wrTitle: myConv?.wrTitle ? myConv.wrTitle : '',
							vendorBusinessName: myConv.vendorBusinessName,
							vendorFirstName: myConv.vendorFirstName,
							vendorLastName: myConv.vendorLastName,
							vendorPhoto: myConv.vendorPhoto,
							vendorId: myConv.vendorId ? myConv.vendorId : '',

							userFirstName: myConv.userFirstName,
							userLastName: myConv.userLastName,
							userPhoto: myConv.userPhoto,
							createdAt: myConv.createdAt ? myConv.createdAt : '',
							_isCanceled,
						};

						chatListTemp.push(obj);
						// console.log(index, 'obj --->', obj);
						++index;
					}

					// console.log('### 3- end chatList ---->', chatListTemp);
					this._loadChat = false;
					resolve(chatListTemp);
				} else {
					this._loadChat = false;
					resolve([]);
				}
			} catch (e) {
				console.log('### 3- end chatList err ->', e);
				this._loadChat = false;
				resolve([]);
			}
		});
	}

	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------
	//-----------------------------------------

	async execute(show: boolean = false) {
		try {
			// find open WR --------------------------------------------------
			const workRequestsList = await this.firebaseApi.findAll(
				'workRequests',
				(ref: any) =>
					ref
						.where('status', '==', 'finished')
						.where('progress', '==', 'open')
			);

			console.log('1 ++++++> workRequestsList', workRequestsList.length);

			// find vendors - approved to work -------------------------------
			const vendorsList = await this.firebaseApi.findAll(
				'users',
				(ref: any) => ref.where('approvedToWork', '==', true)
			);
			console.log('2 ++++++++++++++++> hiredTasks', vendorsList.length);

			// find tasks -----------------------------------------------
			const tasks = await this.firebaseApi.findAll('tasks');

			console.log('2-1 ++++++++++++++++> tasks', tasks.length);

			// find hired tasks --------------------------------------------
			const hiredTasks = tasks.filter((el: any) => el.hired);

			console.log('3 ++++++++++++++++> hiredTasks', hiredTasks.length);

			// find Questionnaires--------------------------------------------
			const vq = await this.firebaseApi.findAll(
				'questionnaire',
				(ref: any) => ref.where('completed', '==', true)
			);

			console.log('4 ++++++++++++++++> vq', vq.length);

			// complete vendors details
			const vendors = await this.compeleteVendorsDetails(
				vendorsList,
				vq,
				hiredTasks
			);

			console.log('5 ++++++++++++++++> vendors', vendors.length);

			// distribute / auto assign wr
			const distributedWorkRequests = await this.distributeWr(
				workRequestsList,
				vendors
			);

			console.log(
				'6 ++++++> workRequests',
				distributedWorkRequests.length
			);

			await this.savePotentialVendorsToDB(distributedWorkRequests);

			await this.saveAutoAssignedTasks(distributedWorkRequests, tasks);

			console.log('11 ++++++++++++++++> done');

			if (show) {
				console.log('success ->', { msg: 'success!' });
			}
		} catch (e) {
			console.log('e->', e);
		}
	}

	/**-------------------------------------------------------------------------
	 *
	 * @param vendors
	 * @param hiredTasks
	 * @returns
	 */
	async compeleteVendorsDetails(vendors: any, vq: any, hiredTasks: any) {
		const adminId = 'Z9V9fHWVEhXWp0CaMJDkeIXRC082';
		const adminQuestionnaireId = 'JzeYyfBas0F2NZfcOFH7';

		return new Promise<any>(async (resolve, reject) => {
			for (const vendor of vendors) {
				let dist = {
					approvedToWork: vendor.approvedToWork,
					autoTopOff: vendor.autoTopOff,
					autoTopOffAmount: vendor.autoTopOffAmount,
					completed: vendor.completed,
					credits: vendor.credits,
					payMethod: vendor.payMethod,
					stripeCards: vendor.stripeCards,
					states: [],
					howManyTimesHired: 0,

					// potential purchase
					personalTax: false,
					businessTax: false,
					nonProfit: false,
					companyForm: false,
					accountingBookkeeping: false,
					payroll: false,

					taxAudit: false,
					notice: true,
					taxConsulation: true,

					// auto purchase
					accountingBookkeepingSettings: null,
					businessSettings: null,
					companyFormation: null,
					payrollSettings: null,
					personalSettings: null,

					taxResolutionsSettings: null,
				};

				const howManyTimeHired = hiredTasks.filter(
					(el) => el.vendorId === vendor.id
				);
				if (howManyTimeHired && howManyTimeHired.length > 0) {
					dist.howManyTimesHired = howManyTimeHired.length;
				}

				const idx = vq.findIndex(
					(el) => vendor.questionnaireId === el.id && el.completed
				);

				let q: any;
				if (idx >= 0) {
					const vQuestion = vq[idx]?.storedAnswersJson
						? vq[idx]?.storedAnswersJson
						: [];

					// find states
					q = vQuestion.find((q: any) => q.key === 'question-2');
					if (q && q.key) {
						dist.states = q.answers.filter(
							(s: any) => s.taget && s.value
						);
					}

					// check 'Personal Tax Return'
					q = vQuestion.find((q: any) => q.key === 'question-4');
					if (q && q.key) {
						const f = q.answers.filter((a: any) => a.value);
						if (f && f.length > 0) dist.personalTax = true;
					}

					// Business Tax Return'
					q = vQuestion.find((q: any) => q.key === 'question-5');
					if (q && q.key) {
						const f = q.answers.filter(
							(a: any) =>
								(a.key === 'c-corp-1120' && a.value) ||
								(a.key === 's-corp-1120s' && a.value) ||
								(a.key === 'llc-1065' && a.value) ||
								(a.key === 'sole-proprietor' && a.value)
						);
						if (f && f.length > 0) dist.businessTax = true;
					}

					// Non-Profit Tax Return
					q = vQuestion.find((q: any) => q.key === 'question-5');
					if (q && q.key) {
						const f = q.answers.filter(
							(a: any) => a.key === 'tax-exempt-990' && a.value
						);
						if (f && f.length > 0) dist.nonProfit = true;
					}

					// Accounting or Bookkeeping
					q = vQuestion.find((q: any) => q.key === 'question-7');
					if (q && q.key) {
						const f = q.answers.filter(
							(a: any) =>
								(a.key === 'accounting' && a.value) ||
								(a.key === 'Bookkeeping' && a.value)
						);
						if (f && f.length > 0)
							dist.accountingBookkeeping = true;
					}

					// Payroll
					q = vQuestion.find((q: any) => q.key === 'question-7');
					if (q && q.key) {
						const f = q.answers.filter(
							(a: any) => a.key === 'payroll' && a.value
						);
						if (f && f.length > 0) dist.payroll = true;
					}

					// Audit protection
					q = vQuestion.find(
						(q: any) => q.key === 'audit-protection'
					);
					if (q && q.key) {
						const f = q.answers.filter(
							(a: any) => a.key === 'yes' && a.value
						);
						if (f && f.length > 0) dist.taxAudit = true;
					}

					// add purchase details

					dist.accountingBookkeepingSettings = vq[idx]
						?.accountingBookkeepingSettings
						? vq[idx].accountingBookkeepingSettings
						: null;

					dist.businessSettings = vq[idx]?.businessSettings
						? vq[idx].businessSettings
						: null;

					if (
						vq[idx]?.companyFormation &&
						vq[idx]?.id === adminQuestionnaireId
					) {
						dist.companyFormation = vq[idx].companyFormation;
						dist.companyForm = true;
					}

					dist.payrollSettings = vq[idx]?.payrollSettings
						? vq[idx].payrollSettings
						: null;

					dist.personalSettings = vq[idx]?.personalSettings
						? vq[idx].personalSettings
						: null;

					dist.taxResolutionsSettings = vq[idx]
						?.taxResolutionsSettings
						? vq[idx].taxResolutionsSettings
						: null;
				}

				vendor.dist = dist;
			}

			resolve(vendors);
		});
	}

	/**---------------------------------------------------------
	 *
	 * @param workRequests
	 * @param vendors
	 * @returns
	 */
	async distributeWr(workRequests: any, vendors: any) {
		return new Promise<any>((resolve, reject) => {
			for (const wr of workRequests) {
				const potentialVendors = vendors.filter((v: any) => {
					// check wr/vendor same states
					let _statesChecked = true;
					const tagetStates = v.dist?.states
						? v.dist.states.map((state: any) => state.name)
						: [];
					const wrStates = getStatesList(wr);

					// compare states
					if (wrStates && wrStates.length > 0) {
						if (tagetStates && tagetStates.length > 0) {
							wrStates.forEach((wrSt: any) => {
								if (!tagetStates.includes(wrSt.name))
									_statesChecked = false;
							});
						} else _statesChecked = false;
					}

					console.log('++++++++++++++++++++++++++++++++++++++++++');
					console.log('vendor -=-=>>>>-', v.email);
					console.log('wr states -=-=>>>>-', wrStates);
					console.log('vendor taget states -=-=>>>>-', tagetStates);
					console.log('potential vendor -=-=>>>>-', _statesChecked);

					// heck wr type
					if (_statesChecked) {
						switch (wr.requestType) {
							case 'Personal Tax Return':
								if (v.dist.personalTax) return v;
								break;

							case 'Business Tax Return':
								if (v.dist.businessTax) return v;
								break;

							case 'Company Formation':
								if (v.dist.companyForm) return v;
								break;

							case 'Non-Profit Tax Return':
								if (v.dist.nonProfit) return v;
								break;

							case 'Accounting or Bookkeeping':
								if (v.dist.accountingBookkeeping) return v;
								break;

							case 'Payroll':
								if (v.dist.payroll) return v;
								break;

							case 'Tax Audit / Lien / Levy':
								if (v.dist.taxAudit) return v;
								break;

							case 'I Received a Notice':
								if (v.dist.notice) return v;
								break;

							case 'Tax Consultation/Other Issues':
								if (v.dist.taxConsulation) return v;
								break;

							default:
						}
					}
				});

				if (potentialVendors.length === 0) {
					wr.potentialVendors = [];
					wr.autoAssign = [];
				} else {
					wr.potentialVendors = potentialVendors;

					//check auto assign
					const wRDate = wr.payment.created * 1000;
					const oneDay = 1 * 24 * 60 * 60 * 1000;
					const nowDate = new Date().getTime() - oneDay;

					const chargeAmount = getVendorChargeAmount(wr);
					wr.vendorChargeAmount = chargeAmount;

					//check autoAssign
					const autoAssign = potentialVendors.filter(
						async (v: any) => {
							if (
								v.dist.autoTopOff &&
								v.dist.autoTopOffAmount > 0
							) {
								switch (wr.requestType) {
									case 'Personal Tax Return':
										if (
											v.dist?.personalSettings &&
											checkAvailableMoney(
												v.dist.personalSettings,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist.personalSettings
													.budgetSpent
											)
												v.dist.personalSettings.budgetSpent +=
													chargeAmount;
											else
												v.dist.personalSettings.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												personalSettings:
													v.dist.personalSettings,
											};
											return v;
										}
										break;

									case 'Business Tax Return':
										if (
											v.dist?.businessSettings &&
											checkAvailableMoney(
												v.dist.businessSettings,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist.businessSettings
													.budgetSpent
											)
												v.dist.businessSettings.budgetSpent +=
													chargeAmount;
											else
												v.dist.businessSettings.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												businessSettings:
													v.dist.businessSettings,
											};
											return v;
										}
										break;

									case 'Accounting or Bookkeeping':
										if (
											v.dist
												?.accountingBookkeepingSettings &&
											checkAvailableMoney(
												v.dist
													.accountingBookkeepingSettings,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist
													.accountingBookkeepingSettings
													.budgetSpent
											)
												v.dist.accountingBookkeepingSettings.budgetSpent +=
													chargeAmount;
											else
												v.dist.accountingBookkeepingSettings.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												accountingBookkeepingSettings:
													v.dist
														.accountingBookkeepingSettings,
											};
											return v;
										}
										break;

									case 'Payroll':
										if (
											v.dist?.payrollSettings &&
											checkAvailableMoney(
												v.dist.payrollSettings,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist.payrollSettings
													.budgetSpent
											)
												v.dist.payrollSettings.budgetSpent +=
													chargeAmount;
											else
												v.dist.payrollSettings.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												payrollSettings:
													v.dist.payrollSettings,
											};
											return v;
										}
										break;

									case 'Company Formation':
										if (
											v.dist?.companyForm &&
											checkAvailableMoney(
												v.dist.companyFormation,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist.companyFormation
													.budgetSpent
											)
												v.dist.companyFormation.budgetSpent +=
													chargeAmount;
											else
												v.dist.companyFormation.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												companyFormation:
													v.dist.companyFormation,
											};
											return v;
										}
										break;

									case 'Non-Profit Tax Return':
									case 'Tax Audit / Lien / Levy':
									case 'I Received a Notice':
									case 'Tax Consultation/Other Issues':
										if (
											v.dist?.taxResolutionsSettings &&
											checkAvailableMoney(
												v.dist.taxResolutionsSettings,
												chargeAmount,
												v.dist.credits
											)
										) {
											if (
												v.dist.taxResolutionsSettings
													.budgetSpent
											)
												v.dist.taxResolutionsSettings.budgetSpent +=
													chargeAmount;
											else
												v.dist.taxResolutionsSettings.budgetSpent =
													chargeAmount;
											v.dist.updateData = {
												taxResolutionsSettings:
													v.dist
														.taxResolutionsSettings,
											};
											return v;
										}
										break;
									default:
								}
							}
						}
					);
					wr.autoAssign =
						autoAssign && autoAssign.length ? autoAssign : [];
				}
			}
			resolve(workRequests);
		});

		/**
		 *
		 * @param request
		 * @returns
		 */
		function getStatesList(request: any) {
			let states = [];

			// main states
			if (request?.questionnaire[5]?.answers) {
				const st = request.questionnaire[5].answers.filter(
					(a) => a.value === true
				);
				if (st && st.length > 0) states = st;
			}

			// comapany states
			if (request?.bizList && request?.bizList.length > 0) {
				request?.bizList.forEach((el: any) => {
					if (el && el.data && el.data.length > 0) {
						const st = el.data.find((dt: any) =>
							[
								'q5',
								'q16.1',
								'q23.39',
								'q25.39',
								'q27.3',
								'q47.2',
							].includes(dt.key)
						);

						if (st && st.answers) {
							const selectedStates = st.answers.filter(
								(s: any) => s.value
							);
							states = [...states, ...selectedStates];
						}
					}
				});
			}

			return states;
		}

		/**
		 *
		 * @param wr
		 * @returns
		 */
		function getVendorChargeAmount(wr: any) {
			let vendorChargeAmount: number = 0;
			switch (wr.requestType) {
				case 'Personal Tax Return':
					if (
						wr?.packageType?.length === 1 &&
						wr?.packageType.includes('business')
					)
						vendorChargeAmount = 50;
					else if (
						wr.packageType?.length > 1 &&
						wr.packageType?.includes('business')
					)
						vendorChargeAmount = 70;
					else vendorChargeAmount = 30;
					break;
				default:
					vendorChargeAmount = 30;
			}

			const percentOfTotal = wr?.payment?.globalTotal * 0.15;
			return vendorChargeAmount + percentOfTotal;
		}

		/**
		 *
		 * @param data
		 * @param chargeAmount
		 * @param credits
		 * @returns
		 */
		function checkAvailableMoney(
			data: any,
			chargeAmount: number,
			credits: number
		): boolean {
			let status = false;
			if (data && data.budget) status = true;
			else if (data.budgetAmt) {
				const spent = data?.budgetSpent ? data?.budgetSpent : 0;
				const availableMoney = data.budgetAmt - spent;
				if (availableMoney >= chargeAmount && credits >= chargeAmount)
					status = true;
			}
			return status;
		}
	}

	async savePotentialVendorsToDB(distributedWorkRequests: any) {
		console.log(' 7 savePotentialVendorsToDB started ');
		return new Promise(async (resolve, reject) => {
			try {
				for (let workRequest of distributedWorkRequests) {
					const potentialVendors = workRequest.potentialVendors.map(
						(p: any) => p.id
					);
					const autoAssign = workRequest.autoAssign.map(
						(p: any) => p.id
					);
					const update = {
						potentialVendors,
						autoAssign,
						locked: true,
					};
					console.log('upd wr- data =->', workRequest.id, update);

					await this.firebaseApi.updateOne(
						'workRequests',
						workRequest.id,
						update
					);
				}
				resolve(true);
				console.log(' 8 savePotentialVendorsToDB ended ');
			} catch (e) {
				console.log('save savePotentialVendorsToDB err', e);
				reject({
					error: e,
					msg: 'Error saving distributed work requests to DB',
				});
			}
		});
	}

	/**----------------------------------------------------------------
	 *
	 * @param autoAssignedTasks
	 * @param workRequests
	 * @param tasks
	 * @returns
	 */
	async saveAutoAssignedTasks(workRequests: any, tasks: any) {
		console.log('9 saveAutoAssignedTasks started');
		return new Promise(async (resolve, reject) => {
			try {
				for (const wr of workRequests) {
					if (wr && wr.autoAssign.length > 0) {
						const users = await this.firebaseApi.findOneById(
							'users',
							wr.userId
						);

						const user = users && users.id ? users : {};
						console.log('user -> ', user);

						for (const vendor of wr.autoAssign) {
							const taskExists = tasks.find(
								(t: any) =>
									wr.id === t.workRequestId &&
									vendor.id === t.vendorId
							);

							if (!taskExists) {
								// find default template & attached files

								const defaultTemplate =
									await this.firebaseApi.findAll(
										'templates',
										(ref: any) =>
											ref
												.where(
													'vendorId',
													'==',
													vendor.id
												)
												.where(
													'defaultTemplate',
													'==',
													true
												)
									);

								console.log(
									'default template ->',
									defaultTemplate
								);
								let msg = [];
								let fi = [];
								if (
									defaultTemplate &&
									defaultTemplate.length > 0
								) {
									const myMessage = this.cleanMesage(
										defaultTemplate[0].message,
										user,
										vendor
									);

									msg = [
										{
											vend: myMessage,
											postedDate:
												new Date().toISOString(),
										},
									];
									if (
										defaultTemplate[0].templateFiles &&
										defaultTemplate[0].templateFiles
											.length > 0
									)
										fi = defaultTemplate[0].templateFiles;
								}

								console.log('my message', msg);
								//----------------------------------------
								const taskInsert = {
									userId: wr.userId,
									vendorId: vendor.id,
									messages: msg,
									templateFiles: fi,
									workRequestId: wr.id,
									vendorPhoto: vendor.profilePhoto
										? vendor.profilePhoto
										: '',
									vendorName:
										vendor.firstName +
										' ' +
										vendor.lastName,
									hired: false,
									auto: true,
									charged: true,
									createdAt: new Date(),
									clientFirstMsg: wr?.clientFirstMsg
										? wr.clientFirstMsg
										: '',

									requestType: wr?.requestType
										? wr.requestType
										: '',
									vendorFirstName: vendor?.firstName
										? vendor.firstName
										: '',
									vendorLastName: vendor?.lastName
										? vendor.lastName
										: '',
									vendorBusinessName: vendor?.business?.name
										? vendor.business.name
										: '',

									userFirstName: user?.firstName
										? user.firstName
										: '',
									userLastName: user?.lastName
										? user.lastName
										: '',
									userPhoto: user?.profilePhoto
										? user.profilePhoto
										: '',
								};

								console.log(
									'9-1 taskInsert :  -=>',
									taskInsert
								);

								if (
									(vendor.credits &&
										vendor.credits >=
											wr.vendorChargeAmount) ||
									vendor.autoTopOff
								) {
									// check tasks exist
									console.log(
										'9-2-1 userId, vendorId, wrId-=>',
										wr.userId,
										vendor.id,
										wr.id
									);

									const getTasks =
										await this.firebaseApi.findAll(
											'tasks',
											(ref) =>
												ref
													.where(
														'userId',
														'==',
														wr.userId
													)
													.where(
														'vendorId',
														'==',
														vendor.id
													)
													.where(
														'workRequestId',
														'==',
														wr.id
													)
										);

									console.log(
										'9-2-2 task exist? -=>, getTasks len ,questionnaireId ',
										getTasks.length,
										vendor.questionnaireId
									);
									console.log(
										'9-3 questionnaireId -=>',
										vendor.questionnaireId,
										vendor.id
									);

									if (
										getTasks &&
										getTasks.length === 0 &&
										vendor &&
										vendor.questionnaireId
									) {
										await this.submitAutoApprovalAndCharge(
											taskInsert,
											wr,
											vendor
										);
									}
								}
							}
						}
					}
				}
				console.log('10 saveAutoAssignedTasks ended');
				resolve(true);
			} catch (e) {
				console.log('saveAutoAssignedTasks err', e);
				reject({
					error: e,
					msg: 'Error saving auto assigned tasks',
				});
			}
		});
	}

	/**----------------------------------------------------------------
	 *
	 * @param task
	 * @param taskInsert
	 * @param vendorId
	 * @param chargeAmount
	 * @returns
	 */
	async submitAutoApprovalAndCharge(taskInsert: any, wr: any, vendor: any) {
		return new Promise(async (resolve, reject) => {
			try {
				console.log('9-3 saving new task :  -=>', taskInsert);

				// insert new task = auto purchase
				const docRef = await this.firebaseApi.insertOne(
					'tasks',
					taskInsert
				);

				console.log(
					'9-4 charging vendor , new task id  -=>',
					docRef,
					wr.vendorChargeAmount
				);

				// charge vendor after create a new task
				const response: any = await this.chargeVendor(
					vendor,
					docRef,
					wr.vendorChargeAmount
				);

				console.log(
					'9<->5 submitAutoApprovalAndCharge response',
					response.code,
					response
				);

				console.log(
					'9<->50 auto update - the auto purchase',
					vendor.questionnaireId,
					vendor.dist.updateData
				);

				// update auto purchase in questionnaire
				if (response.code === 'success') {
					await this.firebaseApi.updateOne(
						'questionnaire',
						vendor.questionnaireId,
						vendor.dist.updateData
					);
				}

				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**---------------------------------------------------------------------------
	 *
	 * @param vendorId
	 * @param taskId
	 * @param amount
	 * @param manual
	 * @returns
	 */
	async chargeVendor(vendor, taskId, amount, manual?) {
		return new Promise(async (resolve, reject) => {
			try {
				let p: any = {};
				let currentCredit = vendor.credits;

				if (
					vendor.autoTopOff &&
					vendor.autoTopOffAmount > 0 &&
					vendor.credits < amount &&
					vendor.stripeCards &&
					vendor.stripeCards.length > 0
				) {
					const cardDetails = vendor.stripeCards.find(
						(el: any) => el.default
					);
					console.log('1 charge card-details ->', cardDetails);

					if (
						cardDetails &&
						cardDetails.customer &&
						cardDetails.payment_method
					) {
						p = await this.chargeVendorForCredits(
							vendor.autoTopOffAmount,
							cardDetails.payment_method,
							cardDetails.customer,
							vendor.id,
							vendor.credits
						);
						if (p && p?.credits) {
							currentCredit = p.credits;
							console.log('5-1 charge p res ->', p);
						}
					}
				}

				console.log('5-2 charge p res ->', currentCredit);

				if (currentCredit >= amount) {
					console.log(
						'9-6 updating vendor current credit -=>',
						currentCredit,
						' - ',
						amount
					);

					currentCredit -= amount;

					// update vendor credit
					await this.firebaseApi.updateOne('users', vendor.id, {
						credits: currentCredit,
					});

					console.log('9-6 vendor credit updated -=>', currentCredit);

					if (!manual) {
						const data = {
							userId: vendor.id,
							taskId: taskId,
							amount: amount,
							createdAt: new Date(),
						};

						const docRef = await this.firebaseApi.insertOne(
							'leadpurchases',
							data
						);

						console.log('9-7 adding leadpurchases rec -=>', data);

						await this.firebaseApi.updateOne('tasks', taskId, {
							charged: true,
						});

						console.log('9-8 task updated charged = true');
					}
					resolve({
						code: 'success',
						msg: 'Success',
						newCredits: currentCredit,
					});
				} else {
					resolve({
						code: 'not-enough-credits',
						msg: 'Vendor does not have enough credits',
						newCredits: currentCredit ? currentCredit : 0,
					});
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	/**------------------------------------------------------------------------------
	 *
	 * @param amount
	 * @param payment_method
	 * @param vendorId
	 * @param existingCredits
	 * @returns
	 */
	async chargeVendorForCredits(
		autoTopOffAmount,
		payment_method,
		customer,
		vendorId,
		existingCredits
	) {
		return new Promise<{ charge: any; credits: any }>(
			async (resolve, reject) => {
				try {
					const chargeData = {
						amount: autoTopOffAmount * 100,
						currency: 'usd',
						payment_method,
						customer,
						confirm: true,
						automatic_payment_methods: {
							enabled: true,
							allow_redirects: 'never',
						},
					};

					console.log('2 charge data', chargeData);

					//-=-=-=-=- stripe payment
					this.stripePay(
						chargeData,
						'https://api.stripe.com/v1/payment_intents'
					).subscribe({
						next: async (charge) => {
							console.log('3 charge charge', charge);

							const docRef = await this.firebaseApi.insertOne(
								'creditpurchases',
								{
									userId: vendorId,
									charge,
									createdAt: new Date(),
								}
							);

							const credits = existingCredits
								? existingCredits + autoTopOffAmount
								: autoTopOffAmount;

							await this.firebaseApi.updateOne(
								'users',
								vendorId,
								{
									credits,
								}
							);

							console.log('4 charge credits', credits);

							resolve({ charge, credits });
						},
						error(err) {
							console.log('err...........', err);
						},
						complete() {
							console.log('completed...........');
						},
					});
					//-=--=-=-=-
				} catch (e) {
					reject(e);
				}
			}
		);
	}

	cleanMesage(msg: any, u: any, v: any) {
		if (!msg) return;
		const regularMsg = msg
			.replace(/\(f\)/gi, v.firstName)
			.replace(/\(l\)/gi, v.lastName)
			.replace(/\(a\)/gi, v.address.line1)
			.replace(/\(c\)/gi, v.address.city)
			.replace(/\(s\)/gi, v.address.state + v.address.zipcode)
			.replace(/\(p\)/gi, v.phoneNumber)
			.replace(/\(e\)/gi, v.email)

			.replace(/\(c_f\)/gi, u.firstName)
			.replace(/\(c_l\)/gi, u.lastName)
			.replace(/\(c_a\)/gi, u.address.line1)
			.replace(/\(c_c\)/gi, u.address.city)
			.replace(/\(c_s\)/gi, u.address.state + v.address.zipcode)
			.replace(/\(c_p\)/gi, u.phoneNumber)
			.replace(/\(c_e\)/gi, u.email);
		return regularMsg;
	}
}
