<div id="chatIconClose" (click)="dismissModal()">
	<ion-icon name="close-circle-outline"></ion-icon>
</div>
<ion-grid style="width: 80%">
	<ion-row>
		<ion-col>
			<p class="disclaimer">
				You are leaving a review for {{ componentProps?.vendorName }}
			</p>
		</ion-col>
	</ion-row>
	<ion-row id="reviewBox">
		<ion-col size="12">
			<form
				id="form"
				#reviewerForm="ngForm"
				(ngSubmit)="onSubmitReview(reviewerForm)"
			>
				<ion-row>
					<ion-col>
						<div id="stars">
							<app-star-rating
								(ratingChange)="ratingChange($event)"
							></app-star-rating>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<div class="input-container">
							<div class="custom-label">Review Message</div>
							<ion-textarea
								style="padding: 0.5rem 1rem"
								name="reviewMessage"
								[(ngModel)]="reviewMessage"
							></ion-textarea>
						</div>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col>
						<button class="pointer custom-btn">
							<span *ngIf="!submitReviewSpinner"
								>Submit Review</span
							>
							<ion-spinner
								color="light"
								name="circles"
								*ngIf="submitReviewSpinner"
							></ion-spinner>
						</button>
					</ion-col>
				</ion-row>
			</form>
		</ion-col>
	</ion-row>
</ion-grid>
